import { useRouter } from "next/router";
import { useEffect } from "react";
import MetaTags from "react-meta-tags";

export default function Home({ roles }) {
  const router = useRouter();

  const redirectToHome = (pathName, doesRoleExist) => {
    return doesRoleExist && router.push(pathName);
  };

  useEffect(() => {
    redirectToHome(
      "/menus",
      roles.includes("System Admin") || roles.includes("MB Super Admin")
    );
    redirectToHome("/nutritions/pods", roles.includes("Nutritionist"));
    redirectToHome("/podAdminHome", roles.includes("POD Admin"));
    redirectToHome("/ChefAdminHome", roles.includes("Chef Team"));
    redirectToHome("/KitchenAdminHome", roles.includes("Kitchen Admin"));
    redirectToHome("/logisticTeamHome", roles.includes("Logistics Team"));
    redirectToHome("/supportTeam", roles.includes("Support Team"));
    redirectToHome("/packagingTeamHome", roles.includes("Packaging Team"));
    redirectToHome("/financeHome", roles.includes("Finance Admin"));
  }, []);
  return (
    <>
      <MetaTags>
        <title>Monkey Box Admin</title>
      </MetaTags>
      <div className="page-content">Welcom to MonkeyBox Admin</div>
    </>
  );
}
export const getServerSideProps = async ({ req }) => {
  let auth;
  let roles = [];
  if (req.cookies.ADMIN_AUTH) {
    auth = JSON.parse(req.cookies.ADMIN_AUTH);
  }
  if (auth) {
    roles = auth.roles;
  }
  return { props: { roles } };
};
